// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-context-advertising-jsx": () => import("./../../../src/pages/context-advertising.jsx" /* webpackChunkName: "component---src-pages-context-advertising-jsx" */),
  "component---src-pages-create-sites-corporate-jsx": () => import("./../../../src/pages/create-sites/corporate.jsx" /* webpackChunkName: "component---src-pages-create-sites-corporate-jsx" */),
  "component---src-pages-create-sites-e-commerce-jsx": () => import("./../../../src/pages/create-sites/e-commerce.jsx" /* webpackChunkName: "component---src-pages-create-sites-e-commerce-jsx" */),
  "component---src-pages-create-sites-index-jsx": () => import("./../../../src/pages/create-sites/index.jsx" /* webpackChunkName: "component---src-pages-create-sites-index-jsx" */),
  "component---src-pages-create-sites-landing-page-jsx": () => import("./../../../src/pages/create-sites/landing-page.jsx" /* webpackChunkName: "component---src-pages-create-sites-landing-page-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-jsx": () => import("./../../../src/pages/portfolio.jsx" /* webpackChunkName: "component---src-pages-portfolio-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */),
  "component---src-pages-thankyou-jsx": () => import("./../../../src/pages/thankyou.jsx" /* webpackChunkName: "component---src-pages-thankyou-jsx" */)
}

